// /frontend/src/hooks/useServer.js

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchPendingServerInvites } from '../utils/serverAPI';

const useServer = (socket, setPendingServerInvites, updatePendingRequests) => {
  const [serverChannels, setServerChannels] = useState({});

  useEffect(() => {
    if (socket) {
      socket.on('initialServerData', (data) => {
        const newServerChannels = {};
        data.forEach(server => {
          newServerChannels[server.serverId] = server.channels;
        });
        setServerChannels(newServerChannels);
      });

      socket.on('serverUserList', ({ serverId, channels }) => {
        setServerChannels(prev => ({
          ...prev,
          [serverId]: channels
        }));
      });

      socket.on('channelUserList', ({ serverId, channelId, channelData }) => {
        setServerChannels(prev => ({
          ...prev,
          [serverId]: prev[serverId].map(channel => 
            channel._id === channelId ? channelData : channel
          )
        }));
      });

      socket.on('newServerInvite', async () => {
        const invites = await fetchPendingServerInvites();
        setPendingServerInvites(invites);
      });

      socket.on('newFriendRequest', async () => {
        console.log('newFriendRequest');
        await updatePendingRequests();
      });

      return () => {
        socket.off('initialServerData');
        socket.off('serverUserList');
        socket.off('channelUserList');
      };
    }
  }, [socket, setPendingServerInvites, updatePendingRequests]);

  return { serverChannels };
};

export default useServer;
