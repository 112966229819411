// /frontend/src/hooks/useChannel.js

import { useEffect, useState, useRef, useCallback } from 'react';
import * as mediasoupClient from 'mediasoup-client';
import { toast } from 'react-toastify';
import { getUserSettings, setNoiseGateUpdateCallback } from '../utils/serverAPI';
//import useServer from './useServer';  // Import useServer

const useChannel = (socket) => {
  const [device, setDevice] = useState(null);
  const [sendTransport, setSendTransport] = useState(null);
  const [recvTransport, setRecvTransport] = useState(null);
  const [localProducers, setLocalProducers] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [localStream, setLocalStream] = useState(null);
  //const [volume, setVolume] = useState(1.0); // Volume state
  const sendTransportState = useRef('new');
  const recvTransportState = useRef('new');
  const recvTransportRef = useRef(null); // Reference to receive transport
  const pendingProducersRef = useRef([]); // Pending producers waiting for consumption
  const [channelId, setChannelId] = useState(null); // Track current channel ID
  const hasJoinedChannel = useRef(false);
  const [consumerStreams, setConsumerStreams] = useState([]);
  const audioContextRef = useRef(null);
  const masterGainNodeRef = useRef(null); // Rename for clarity
  const gainNodesRef = useRef({}); // For per-consumer gain control
  //const [currentChannelId, setCurrentChannelId] = useState(null);
  //const { channels } = useServer(socket, selectedServerId);  // Use useServer hook
  const [isDeafened, setIsDeafened] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isConnected, setIsConnected] = useState(true);
  const lastKnownChannelIdRef = useRef(null);
  // Ref to store the toast ID
  const disconnectedToastIdRef = useRef(null);
  const [noiseGateThreshold, setNoiseGateThreshold] = useState(getUserSettings().noiseGateThreshold);
  const noiseGateProcessorRef = useRef(null);
  const processedStreamRef = useRef(null);
  const sourceRef = useRef(null);
  const [turnCredentials, setTurnCredentials] = useState(null);

  const showPersistentToast = (message) => {
    return toast.error(message, {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      draggable: false,
      progress: undefined,
    });
  };

  const createNoiseGateProcessor = useCallback(async (threshold) => {
    if (!audioContextRef.current) return null;
  
    if (audioContextRef.current.audioWorklet) {
      // Use AudioWorkletNode
      try {
        await audioContextRef.current.audioWorklet.addModule('/audio/noise-gate-worklet.js');
        const workletNode = new AudioWorkletNode(audioContextRef.current, 'noise-gate-processor', {
          parameterData: { threshold }
        });
  
        // Add method to update threshold
        workletNode.updateThreshold = (newThreshold) => {
          workletNode.port.postMessage({ type: 'setThreshold', value: newThreshold });
        };
  
        // Add method to update attack and release times
        workletNode.updateTimes = (newAttackTime, newReleaseTime) => {
          workletNode.port.postMessage({ 
            type: 'setTimes', 
            attackTime: newAttackTime, 
            releaseTime: newReleaseTime 
          });
        };
  
        return workletNode;
      } catch (error) {
        console.error('Failed to create AudioWorkletNode:', error);
        // Fall back to ScriptProcessorNode if AudioWorklet fails
      }
    }
  
    // Fallback to ScriptProcessorNode
    const bufferSize = 2048;
    const processor = audioContextRef.current.createScriptProcessor(bufferSize, 1, 1);
    
    let envelope = 0;
    let gainEnvelope = 0;
    let attackTime = 0.005; // 5ms attack time
    let releaseTime = 0.15; // 150ms release time
    let attack = Math.exp(-1 / (audioContextRef.current.sampleRate * attackTime));
    let release = Math.exp(-1 / (audioContextRef.current.sampleRate * releaseTime));
    let gainAttack = 1 - Math.exp(-1 / (audioContextRef.current.sampleRate * attackTime));
    let gainRelease = 1 - Math.exp(-1 / (audioContextRef.current.sampleRate * releaseTime));
  
    processor.onaudioprocess = (event) => {
      const input = event.inputBuffer.getChannelData(0);
      const output = event.outputBuffer.getChannelData(0);
      
      for (let i = 0; i < input.length; i++) {
        const inputAbs = Math.abs(input[i]);
        
        if (inputAbs > envelope) {
          envelope = attack * (envelope - inputAbs) + inputAbs;
        } else {
          envelope = release * (envelope - inputAbs) + inputAbs;
        }
  
        const targetGain = envelope > threshold ? 1 : 0;
        
        if (targetGain > gainEnvelope) {
          gainEnvelope += gainAttack * (targetGain - gainEnvelope);
        } else {
          gainEnvelope += gainRelease * (targetGain - gainEnvelope);
        }
  
        output[i] = input[i] * gainEnvelope;
      }
    };
  
    // Add method to update threshold for consistency with AudioWorkletNode
    processor.updateThreshold = (newThreshold) => {
      threshold = newThreshold;
    };
  
    // Add method to update attack and release times
    processor.updateTimes = (newAttackTime, newReleaseTime) => {
      attackTime = newAttackTime || attackTime;
      releaseTime = newReleaseTime || releaseTime;
      attack = Math.exp(-1 / (audioContextRef.current.sampleRate * attackTime));
      release = Math.exp(-1 / (audioContextRef.current.sampleRate * releaseTime));
      gainAttack = 1 - Math.exp(-1 / (audioContextRef.current.sampleRate * attackTime));
      gainRelease = 1 - Math.exp(-1 / (audioContextRef.current.sampleRate * releaseTime));
    };
  
    return processor;
  }, []);

  // Apply noise gate to local stream
  useEffect(() => {
    if (!audioContextRef.current || !localStream) return;
  
    let isMounted = true; // To prevent state updates after unmount
  
    const setupNoiseGate = async () => {
      try {
        const noiseGateProcessor = await createNoiseGateProcessor(noiseGateThreshold);
        if (isMounted && noiseGateProcessor) {
          noiseGateProcessorRef.current = noiseGateProcessor;
  
          const source = audioContextRef.current.createMediaStreamSource(localStream);
          sourceRef.current = source; // Store the source node
          const destination = audioContextRef.current.createMediaStreamDestination();
  
          source.connect(noiseGateProcessor);
          noiseGateProcessor.connect(destination);
  
          processedStreamRef.current = destination.stream;
  
          // Update the WebRTC connection with the new processed stream
          if (sendTransport && localProducers.length > 0) {
            const producer = localProducers[0]; // Assuming a single audio producer
            producer.replaceTrack({ track: processedStreamRef.current.getAudioTracks()[0] });
          }
        }
      } catch (error) {
        console.error('Failed to setup noise gate:', error);
      }
    };
  
    setupNoiseGate();
  
    return () => {
      isMounted = false;
      if (noiseGateProcessorRef.current && sourceRef.current) {
        try {
          sourceRef.current.disconnect(noiseGateProcessorRef.current);
          noiseGateProcessorRef.current.disconnect();
        } catch (err) {
          console.warn('Attempted to disconnect nodes that were not connected:', err);
        }
        noiseGateProcessorRef.current = null;
        sourceRef.current = null;
      }
    };
  }, [localStream, createNoiseGateProcessor, noiseGateThreshold, sendTransport, localProducers]);

  // Function to update noise gate threshold with debounce
  const updateNoiseGateThreshold = useCallback((newThreshold) => {
    const debounce = (func, wait) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
      };
    };
  
    const debouncedSet = debounce((threshold) => {
      setNoiseGateThreshold(threshold);
      if (noiseGateProcessorRef.current) {
        noiseGateProcessorRef.current.updateThreshold(threshold);
      }
    }, 100);
  
    debouncedSet(newThreshold);
  }, [setNoiseGateThreshold]);
  
  useEffect(() => {
    setNoiseGateUpdateCallback(updateNoiseGateThreshold);
    return () => setNoiseGateUpdateCallback(null);
  }, [updateNoiseGateThreshold]);

//  useEffect(() => {
//    if (localStream && audioContextRef.current) {
//      const source = audioContextRef.current.createMediaStreamSource(localStream);
//      const destination = audioContextRef.current.createMediaStreamDestination();
//      const noiseGateProcessor = createNoiseGateProcessor(noiseGateThreshold);
//  
//      source.connect(noiseGateProcessor);
//      noiseGateProcessor.connect(destination);
//  
//      processedStreamRef.current = destination.stream;
//    }
//  }, [localStream, createNoiseGateProcessor, noiseGateThreshold]);

  const toggleDeafen = useCallback(() => {
    if (!socket || !channelId) {
      console.error('Cannot toggle deafen: socket or channelId is missing');
      return;
    }

    const newDeafenState = !isDeafened;
    setIsDeafened(newDeafenState);

    if (newDeafenState) {
      socket.emit('deafen', { channelId });
      consumers.forEach(consumer => consumer.pause());
    } else {
      socket.emit('undeafen', { channelId });
      consumers.forEach(consumer => consumer.resume());
    }

    console.log(`User ${newDeafenState ? 'deafened' : 'undeafened'}`);
  }, [socket, channelId, isDeafened, consumers]);

  const toggleMute = useCallback(() => {
    if (!socket || !channelId) {
      console.error('Cannot toggle mute: socket or channelId is missing');
      return;
    }

    const newMuteState = !isMuted;
    setIsMuted(newMuteState);

    if (newMuteState) {
      socket.emit('mute', { channelId });
      localProducers.forEach(producer => producer.pause());
    } else {
      socket.emit('unmute', { channelId });
      localProducers.forEach(producer => producer.resume());
    }

    console.log(`User ${newMuteState ? 'muted' : 'unmuted'}`);
  }, [socket, channelId, isMuted, localProducers]);



  const resumeAudioContext = useCallback(() => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume().then(() => {
        console.log('AudioContext resumed successfully');
      }).catch(error => {
        console.error('Failed to resume AudioContext:', error);
      });
    }
  }, []);

  // Initialize AudioContext and Master GainNode
  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    masterGainNodeRef.current = audioContextRef.current.createGain();
    masterGainNodeRef.current.gain.value = 1.0; // Default global gain

    // Connect Master GainNode to the destination (speakers)
    masterGainNodeRef.current.connect(audioContextRef.current.destination);

    return () => {
      // Cleanup AudioContext on unmount
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  // Function to adjust the global gain with validation
  const setGlobalGain = useCallback((value) => {
    if (masterGainNodeRef.current) {
      if (isFinite(value) && value >= 0) {
        console.log(`Setting global gain to: ${value}`);
        masterGainNodeRef.current.gain.value = value;
      } else {
        console.warn(`Attempted to set global gain to an invalid value: ${value}`);
      }
    }
  }, []);

  // Register function to expose to components
  const registerSetGlobalGain = (fn) => {
    fn(setGlobalGain);
  };

  // Consolidated adjustVolume function to control gain
  const adjustVolume = setGlobalGain;

  // Hander for updated user list
  //  useEffect(() => {
  //    if (!socket) {
  //      console.log('Socket is not available yet');
  //      return;
  //    }
  //
  //    const handleUpdateUserList = (updatedUsers) => {
  //      setUsers(updatedUsers);
  //      console.log('Updated users in channel:', updatedUsers);
  //    };
  //
  //    socket.on('updateUserList', handleUpdateUserList);
  //
  //    return () => {
  //      socket.off('updateUserList', handleUpdateUserList);
  //    };
  //  }, [socket]);

  // Mute or unmute local producer
  const muteProducer = (producerId) => {
    const producer = localProducers.find((p) => p.id === producerId);
    if (producer) {
      producer.pause();
      console.log(`Producer ${producerId} paused.`);
    }
  };

  const unmuteProducer = (producerId) => {
    const producer = localProducers.find((p) => p.id === producerId);
    if (producer) {
      producer.resume();
      console.log(`Producer ${producerId} resumed.`);
    }
  };

  // Mute or unmute consumers
  const muteConsumer = (consumerId) => {
    const consumer = consumers.find((c) => c.id === consumerId);
    if (consumer) {
      consumer.pause();
      console.log(`Consumer ${consumerId} muted.`);
    }
  };

  const unmuteConsumer = (consumerId) => {
    const consumer = consumers.find((c) => c.id === consumerId);
    if (consumer) {
      consumer.resume();
      console.log(`Consumer ${consumerId} unmuted.`);
    }
  };

  // Load Mediasoup device
  const loadDevice = useCallback(async (rtpCapabilities) => {
    if (!rtpCapabilities) {
      console.error('RTP Capabilities are missing');
      return null;
    }

    try {
      const newDevice = new mediasoupClient.Device();
      await newDevice.load({ routerRtpCapabilities: rtpCapabilities });
      console.log('Device loaded successfully');
      return newDevice;
    } catch (error) {
      console.error('Failed to load the device:', error);
      return null;
    }
  }, []);

  // Create Mediasoup transports
  const createTransports = useCallback((newDevice, channelId) => {
    if (!newDevice || !channelId) {
      console.log('newDevice: ', newDevice, 'channelId: ', channelId);
      console.error('Device or Channel ID not ready. Cannot create transports.');
      return;
    }
    console.log('Creating send and receive transports for channel:', channelId);
    socket.emit('createTransport', { channelId, direction: 'send' });
    console.log('Emit createTransport for send.');
    socket.emit('createTransport', { channelId, direction: 'recv' });
    console.log('Emit createTransport for recv.');
  }, [socket]);

  // Single function to handle joining a channel
  const connectToChannel = useCallback(
    (newChannelId) => {
      return new Promise(async (resolve, reject) => {
        console.log('Connecting to channel:', newChannelId);
        if (!newChannelId || !socket) {
          console.log('Cannot connect to channel, channelId or socket is missing.');
          return reject('Cannot connect to channel');
        }

        // Reset the hasJoinedChannel flag
        hasJoinedChannel.current = false;

        try {
          // Initialize local media stream
          console.log('Initializing local media stream...');
          let stream;
          try {
            stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setLocalStream(stream);
          } catch (error) {
            console.error('Error accessing microphone:', error);
            // Inform the user that microphone access is required
            return reject('Microphone access is required to join a channel');
          }
          console.log('Local media stream initialized.');

          console.log(`Joining channel: ${newChannelId}`);
          socket.emit('joinChannel', { channelId: newChannelId }, (response) => {
            if (response.error) {
              console.error('Error joining channel:', response.error);
              setIsConnected(false);
              // Stop the stream if we couldn't join the channel
              stream.getTracks().forEach(track => track.stop());
              setLocalStream(null);
              return reject(response.error);
            } else {
              console.log('Channel joined successfully.');
              setIsConnected(true);
              setChannelId(newChannelId); // Set channel ID
              lastKnownChannelIdRef.current = newChannelId;
              hasJoinedChannel.current = true; // Mark the channel as joined
              resolve(newChannelId);
            }
          });
        } catch (error) {
          console.error('Failed to initialize local media stream:', error);
          setIsConnected(false);
          reject(error);
        }
      });
    },
    [socket]
  );

  // Consume producers
  const consumeProducers = useCallback(
    async (producers) => {
      if (!socket) {
        console.log('Socket is not available yet');
        return;
      }

      if (!recvTransportRef.current) {
        console.log('Recv transport not ready, cannot consume producers yet.');
        pendingProducersRef.current.push(...producers);
        return;
      }
      console.log('Attempting to consume producers:', producers);
      for (const { producerId, channelId, userId } of producers) {
        try {
          socket.emit(
            'consume', 
            { producerId, rtpCapabilities: device.rtpCapabilities, channelId },
            async (consumerData) => {
            if (consumerData.error) {
              console.error('Error consuming producer:', consumerData.error);
              return;
            }
            console.log(`Consuming producer: ${producerId}`);
            const consumer = await recvTransportRef.current.consume({
              id: consumerData.id,
              producerId: consumerData.producerId,
              kind: consumerData.kind,
              rtpParameters: consumerData.rtpParameters,
            });
            setConsumers((prev) => [...prev, consumer]);
            
            console.log('Consumer created:', consumer);
            
            // Create a new MediaStream and add the track
            const stream = new MediaStream([consumer.track]);
            
            // Log the stream and its tracks
            console.log('MediaStream after adding track:', stream);
            console.log('MediaStream tracks:', stream.getTracks());
            
            // Update state with new consumer and stream, including userId
            setConsumerStreams((prevStreams) => [
              ...prevStreams, 
              { 
                id: consumer.id, 
                stream, 
                volume: 1.0, 
                userId: userId 
              }
            ]);

            // Handle consumer events
            consumer.on('trackended', () => {
              console.log('Consumer track ended.');
              setConsumerStreams((prevStreams) => prevStreams.filter((cs) => cs.id !== consumer.id));
              consumer.close();
              setConsumers((prev) => prev.filter((c) => c.id !== consumer.id));
            });

            consumer.on('transportclose', () => {
              setConsumerStreams((prevStreams) => prevStreams.filter((cs) => cs.id !== consumer.id));
              console.log('Consumer transport closed.');
              setConsumers((prev) => prev.filter((c) => c.id !== consumer.id));
            });
          });
        } catch (err) {
          console.error('Error consuming producer:', err);
        }
      }
    },
    [socket, device]
  );

  const cleanupResources = useCallback((userInitiated = false) => {
    if (userInitiated) {
      // Prevent automatic reconnection by clearing the last known channel ID
      lastKnownChannelIdRef.current = null;
    }
    setIsConnected(false);
    
    // Close send transport
    if (sendTransport) {
      console.log('Closing send transport.');
      sendTransport.removeAllListeners();
      sendTransport.close();
      setSendTransport(null);
    }
  
    // Close receive transport
    if (recvTransportRef.current) {
      console.log('Closing receive transport.');
      recvTransportRef.current.removeAllListeners();
      recvTransportRef.current.close();
      recvTransportRef.current = null;
      setRecvTransport(null);
    }
  
    // Close local producers and stop tracks
    localProducers.forEach((producer) => {
      console.log(`Closing local producer ${producer.id}.`);
      producer.close();
      if (producer.track) {
        producer.track.stop();
        console.log(`Stopped track for producer ${producer.id}.`);
      }
    });
    setLocalProducers([]);
  
    // Close consumers and stop tracks
    consumers.forEach((consumer) => {
      console.log(`Closing consumer ${consumer.id}.`);
      consumer.close();
      if (consumer.track) {
        consumer.track.stop();
        console.log(`Stopped track for consumer ${consumer.id}.`);
      }
    });
    setConsumers([]);

    // Stop local stream tracks
    if (localStream) {
      localStream.getTracks().forEach(track => {
        track.stop();
        console.log(`Stopped local stream track: ${track.kind}`);
      });
      setLocalStream(null);
    }

    // Stop processed stream tracks
    if (processedStreamRef.current) {
      processedStreamRef.current.getTracks().forEach(track => {
      track.stop();
        console.log(`Stopped processed stream track: ${track.kind}`);
      });
      processedStreamRef.current = null;
    }
  
    console.log('Resources cleaned up');
  }, [sendTransport, recvTransportRef, localProducers, consumers, localStream]);

  // Disconnect from the channel
  const disconnectFromChannel = useCallback((userInitiated = false) => {
    if (!channelId || !socket) {
      console.log('No channel or socket available to disconnect.');
      return;
    }

    console.log(`Leaving channel: ${channelId}`);

    let isCallbackCalled = false;

    // Set a timeout to ensure cleanup happens even if the server doesn't respond
    const timeoutId = setTimeout(() => {
      if (!isCallbackCalled) {
        console.warn('Timeout: leaveChannel callback not received. Cleaning up resources.');
        cleanupResources(userInitiated);
      }
    }, 5000); // 5 seconds timeout

    if (socket.connected) {
      socket.emit('leaveChannel', { channelId }, (response) => {
        isCallbackCalled = true;
        clearTimeout(timeoutId); // Clear the timeout since callback was received

        if (response && response.error) {
          console.error('Error leaving channel:', response.error);
          //toast.error(`Failed to leave channel: ${response.error}`);
        } else {
          cleanupResources(userInitiated);
          console.log('Successfully left the channel');
          toast.success('Successfully disconnected from the channel.');
        }
        });
      } else {
        console.warn('Socket is not connected. Skipping leaveChannel emit.');
        cleanupResources(userInitiated);
      }
    },
    [channelId, socket, cleanupResources]
  );

  const handleTransportCreated = useCallback(async (data) => {
    console.log(`[Transport Created] Direction: ${data.direction}, ID: ${data.transport.id}`);
    const { direction, transport, channelId } = data;

    const iceServers = [
      { urls: 'stun:stun.l.google.com:19302' },
      {
        urls: [
          //'turn:url:443?transport=tcp',
          //'turns:url:443?transport=tcp'
          `turn:${process.env.REACT_APP_TURN_SERVER}:${process.env.REACT_APP_TURN_PORT}?transport=udp`,
          `turn:${process.env.REACT_APP_TURN_SERVER}:${process.env.REACT_APP_TURN_PORT}?transport=tcp`,
          `turns:${process.env.REACT_APP_TURN_SERVER}:${process.env.REACT_APP_TURNS_PORT}?transport=tcp`
          //reorder to turns at top if need to prioritize security, test later for latency..
        ],
        username: turnCredentials ? turnCredentials.username : '',
        credential: turnCredentials ? turnCredentials.credential : ''
      }
    ];

    const transportParams = {
      id: transport.id,
      iceParameters: transport.iceParameters,
      iceCandidates: transport.iceCandidates,
      dtlsParameters: transport.dtlsParameters,
      iceServers: iceServers
    };

    if (direction === 'send') {
      console.log('Handling send transport.');

      // Prevent duplicate send transports
      if (sendTransport && !sendTransport.closed) {
        console.log('Send transport already exists and is active. Skipping creation.');
        return;
      }

      console.log('Creating new send transport.');
      const newSendTransport = device.createSendTransport(transportParams);
      setSendTransport(newSendTransport);

      // Event: Connect
      newSendTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        console.log('Connecting send transport. Channel ID:', channelId);
        socket.emit('connectTransport', { channelId, direction: 'send', dtlsParameters }, (response) => {
          if (response.error) {
            console.error('Error connecting send transport:', response.error);
            errback(new Error(response.error));
          } else {
            console.log('Send transport connected successfully.');
            callback();
          }
        });
      });

      // Event: Produce
      newSendTransport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
        console.log(`Producing media: ${kind}`);
        socket.emit('produce', { transportId: newSendTransport.id, kind, rtpParameters, channelId }, ({ id }) => {
          if (id) {
            callback({ id });
          } else {
            errback(new Error('Produce failed'));
          }
        });
      });

      // Handle Transport State Changes
      newSendTransport.on('connectionstatechange', (state) => {
        sendTransportState.current = state;
        console.log(`Send Transport state changed: ${state}`);
      });

      // Produce Local Stream
      const track = processedStreamRef.current.getAudioTracks()[0];
      if (track) {
        newSendTransport.produce({ track })
          .then((producer) => {
            console.log('Produced local stream:', producer);
            setLocalProducers((prev) => [...prev, producer]);
          })
          .catch((err) => console.error('Error producing local stream:', err));
      } else {
        console.log('No audio track available on local stream.');
      }
    } else if (direction === 'recv') {
      console.log('Handling recv transport.');

      // Prevent duplicate recv transports
      if (recvTransportRef.current && !recvTransportRef.current.closed) {
        console.log('Recv transport already exists and is active. Skipping creation.');
        return;
      }

      console.log('Creating new recv transport.');
      try {
        const newRecvTransport = device.createRecvTransport(transportParams);
        console.log('Recv transport created:', newRecvTransport);
        recvTransportRef.current = newRecvTransport;
        setRecvTransport(newRecvTransport);
        console.log('Setting recv transport:', newRecvTransport);

        // Consume Pending Producers
        if (pendingProducersRef.current.length > 0) {
          console.log('Consuming pending producers after recvTransport is created.');
          consumeProducers(pendingProducersRef.current);
          pendingProducersRef.current = [];
        }

        // Event: Connect
        newRecvTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
          console.log('Connecting recv transport. Channel ID:', channelId, 'dtlsParameters:', dtlsParameters);
          socket.emit('connectTransport', { channelId, direction: 'recv', dtlsParameters }, (response) => {
            if (response.error) {
              console.error('Error connecting recv transport:', response.error);
              errback(new Error(response.error));
            } else {
              console.log('Recv transport connected successfully.');
              callback();
            }
          });
        });

        // Handle Transport State Changes
        newRecvTransport.on('connectionstatechange', (state) => {
          recvTransportState.current = state;
          console.log(`Receive Transport state changed: ${state}`);
          if (state === 'connected') {
            console.log('Recv transport is now connected.');
          }
        });

      } catch (err) {
        console.error('Error creating recv transport:', err);
      }
    }
  }, [sendTransport, device, socket, consumeProducers, turnCredentials]);

  const handleDisconnectSocket = useCallback(() => {
    console.log('Socket disconnected');
    disconnectFromChannel(false); // Socket-initiated
    // Show persistent toast and store its ID
    disconnectedToastIdRef.current = showPersistentToast("Disconnected from server. Attempting to reconnect...");
  }, [disconnectFromChannel]);

  const handleReconnect = useCallback(async () => {
    console.log('Socket reconnected');
    setIsConnected(true);

    if (disconnectedToastIdRef.current) {
      toast.dismiss(disconnectedToastIdRef.current);
      disconnectedToastIdRef.current = null; // Reset the ref after dismissing
    }
    
    if (lastKnownChannelIdRef.current) {
      console.log(`Attempting to rejoin channel: ${lastKnownChannelIdRef.current}`);
      try {
        await connectToChannel(lastKnownChannelIdRef.current);
        console.log(`Successfully rejoined channel: ${lastKnownChannelIdRef.current}`);
        toast.success("Reconnected to voice channel.");
      } catch (error) {
        console.error(`Failed to rejoin channel: ${lastKnownChannelIdRef.current}`, error);
        toast.error("Failed to reconnect to voice channel. Retrying...");
        }
      }
    }, [connectToChannel]);

  useEffect(() => {
    console.log('Socket instance:', socket);
    if (!socket) return;

  socket.on('disconnect', handleDisconnectSocket);
  socket.io.on('reconnect', handleReconnect);
  socket.on('transportCreated', handleTransportCreated);

  return () => {
    socket.off('disconnect', handleDisconnectSocket);
    socket.io.off('reconnect', handleReconnect);
    socket.off('transportCreated', handleTransportCreated);
    };
  }, [socket, handleDisconnectSocket, handleReconnect, handleTransportCreated]);

  // Handle existing producers when joining a channel
  useEffect(() => {
    if (!socket) return;

    const handleExistingProducers = async (producers) => {
      console.log('Existing producers received:', producers);
      if (!recvTransportRef.current) {
        console.log('Recv transport not ready, storing existing producers.');
        pendingProducersRef.current.push(...producers.map((producer) => ({
          producerId: producer.id,
          channelId: producer.channelId,
          userId: producer.userId, // Include userId
        })));
      } else {
        console.log('Consuming existing producers.');
        // Consume each producer
        const producersToConsume = producers.map((producer) => ({
          producerId: producer.id,
          channelId: producer.channelId,
          userId: producer.userId, // Include userId
        }));
        consumeProducers(producersToConsume);
      }
    };

    socket.on('existingProducers', handleExistingProducers);

    return () => {
      socket.off('existingProducers', handleExistingProducers);
    };
  }, [socket, consumeProducers]);

  // Adapt the consumerStreams setup to route through GainNodes
  useEffect(() => {
    consumerStreams.forEach(({ id, stream, volume }) => {
      const audioElement = document.getElementById(`audio-${id}`);
      if (audioElement && stream) {
        try {
        // Only setup the audio routing if it hasn't been done before
        if (!audioElement.dataset.setupComplete) {
          const source = audioContextRef.current.createMediaStreamSource(stream);
          
          // If a GainNode doesn't exist for this consumer, create one
          if (!gainNodesRef.current[id]) {
            const gainNode = audioContextRef.current.createGain();
            gainNode.gain.value = volume; // Initialize with current volume
            gainNodesRef.current[id] = gainNode;

            // Connect the GainNode to the Master GainNode
            gainNode.connect(masterGainNodeRef.current);
          }

          // Connect the source to the GainNode
          source.connect(gainNodesRef.current[id]);

          // Ensure the audio element plays to the speakers
          audioElement.srcObject = stream;
          audioElement.dataset.setupComplete = 'true';

          // Add this block to set the audio output to speaker
          if (typeof audioElement.setSinkId === 'function') {
            audioElement.setSinkId('speaker')
              .then(() => console.log(`Audio output set to speaker for consumer ${id}`))
              .catch(error => console.error(`Error setting audio output for consumer ${id}:`, error));
          }
        }

        // Function to attempt playing with retries
        const attemptPlay = (retries = 3) => {
          resumeAudioContext();
          audioElement.play().then(() => {
            console.log(`Audio playing for consumer ${id}`);
          }).catch((err) => {
            if (retries > 0) {
              console.warn(`Retrying play for consumer ${id}, attempts left: ${retries}`);
              setTimeout(() => attemptPlay(retries - 1), 1000);
            } else {
              console.error(`Error playing audio for consumer ${id} after retries:`, err);
            }
          });
        };

        // Only call play if the audio is not already playing
        if (audioElement.paused) {
          attemptPlay();
        }
      
      } catch (error) {
        console.error(`Error setting up audio for consumer ${id}:`, error);
      }
    }
    });
  }, [consumerStreams, resumeAudioContext]);

  // Adjust volume of a consumer stream
  const adjustStreamVolume = useCallback((userId, value) => {
    if (!audioContextRef.current || !masterGainNodeRef.current) return;

    if (!isFinite(value) || value < 0) {
      console.warn(`Invalid volume value: ${value}`);
      return;
    }

    setConsumerStreams((prevStreams) =>
      prevStreams.map((cs) => {
        if (cs.userId === userId) {
          if (gainNodesRef.current[cs.id]) {
            gainNodesRef.current[cs.id].gain.setValueAtTime(value, audioContextRef.current.currentTime);
          }
          return { ...cs, volume: value };
        }
        return cs;
      })
    );
  }, []);

  // Handle incoming and outgoing media (producers/consumers)
  useEffect(() => {
    if (!socket || !localStream) {
      console.log('Socket or localStream not available, skipping media handling.');
      return;
    }

    const handleChannelJoined = async (data) => {
      console.log('Channel joined event received:', data);
      console.log('Channel ID:', data.channelId);
      try {
        const newDevice = await loadDevice(data.rtpCapabilities);
        if (!newDevice) {
          console.error('Failed to load device');
          return;
        }
        setDevice(newDevice);
        setChannelId(data.channelId);
        lastKnownChannelIdRef.current = data.channelId; // Add this line

        // Store TURN credentials
        setTurnCredentials(data.turnCredentials);

        await createTransports(newDevice, data.channelId);
        console.log('Channel joined successfully.');
      } catch (error) {
        console.error('Error joining channel:', error);
      }
    };

    socket.on('joinedChannel', handleChannelJoined);

    return () => {
      socket.off('joinedChannel', handleChannelJoined);
    };
  }
  );

  // Handle new and existing producers
  useEffect(() => {
    if (!socket) return;

    const handleNewProducerAvailable = async ({ producerId, channelId, userId }) => {
      console.log('New producer available:', producerId, 'Channel ID:', channelId, 'User ID:', userId);
      if (!recvTransportRef.current) {
        console.log('Recv transport not ready, storing producer:', producerId);
        pendingProducersRef.current.push({ producerId, channelId, userId });
      } else {
        console.log('Consuming producer:', producerId);
        consumeProducers([{ producerId, channelId, userId }]);
      }
    };

    socket.on('newProducerAvailable', handleNewProducerAvailable);

    return () => {
      socket.off('newProducerAvailable', handleNewProducerAvailable);
    };
  }, [socket, consumeProducers]);

  return {
    device,
    sendTransport,
    recvTransport,
    localProducers,
    consumers,
    localStream,
    adjustVolume,
    connectToChannel,
    isSendTransportConnected: sendTransportState.current === 'connected',
    isRecvTransportConnected: recvTransportState.current === 'connected',
    disconnectFromChannel,
    muteProducer,
    unmuteProducer,
    muteConsumer,
    unmuteConsumer,
    consumerStreams,
    adjustStreamVolume,
    registerSetGlobalGain,
    resumeAudioContext,
    isDeafened,
    toggleDeafen,
    setIsDeafened,
    isMuted,
    toggleMute,
    setIsMuted,
    isConnected,
    noiseGateThreshold,
    updateNoiseGateThreshold,
  };
};

export default useChannel;