// frontend/src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WebSocketProvider } from './contexts/WebSocketContext'; // Import WebSocketProvider
import Login from './pages/Login';
import Home from './pages/Home';

const App = () => {
  return (
    <WebSocketProvider> {/* Wrap the entire application */}
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
    </WebSocketProvider>
  );
};

export default App;
