import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

const useFriends = () => {
  const [friends, setFriends] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    fetchFriends();
    fetchPendingRequests();
  }, []);

  const fetchFriends = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/friends/list`, { withCredentials: true });
      setFriends(response.data.friends);
    } catch (error) {
      console.error('Failed to fetch friends:', error);
      toast.error('Failed to fetch friends');
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/friends/pending`, { withCredentials: true });
      setPendingRequests(response.data.pendingRequests);
    } catch (error) {
      console.error('Failed to fetch pending requests:', error);
      toast.error('Failed to fetch pending requests');
    }
  };

  // Expose this function to be called from outside
  const updatePendingRequests = () => {
    fetchPendingRequests();
  };

  const addFriend = async (username, email) => {
    try {
      const response = await axios.post(`${API_URL}/api/friends/request`, { username, email }, { withCredentials: true });
      toast.success(response.data.message);
      fetchFriends(); // Refresh the friends list
    } catch (error) {
      console.error('Failed to add friend:', error);
      toast.error(error.response?.data?.message || 'Failed to add friend');
      throw error;
    }
  };

  const acceptFriendRequest = async (requestId) => {
    try {
      const response = await axios.post(`${API_URL}/api/friends/accept`, { requestId }, { withCredentials: true });
      toast.success(response.data.message);
      fetchFriends();
      fetchPendingRequests();
    } catch (error) {
      console.error('Failed to accept friend request:', error);
      toast.error(error.response?.data?.message || 'Failed to accept friend request');
    }
  };

  const rejectFriendRequest = async (requestId) => {
    try {
      const response = await axios.post(`${API_URL}/api/friends/reject`, { requestId }, { withCredentials: true });
      toast.success(response.data.message);
      fetchPendingRequests();
    } catch (error) {
      console.error('Failed to reject friend request:', error);
      toast.error(error.response?.data?.message || 'Failed to reject friend request');
    }
  };

  return {
    friends,
    pendingRequests,
    addFriend,
    acceptFriendRequest,
    rejectFriendRequest,
    fetchFriends,
    fetchPendingRequests,
    updatePendingRequests
  };
};

export default useFriends;