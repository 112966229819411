// frontend/src/pages/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    window.open(`${process.env.REACT_APP_BACKEND_URL}/auth/google`, '_self');
  };

  const handleDevLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/dev-login`, { username }, { withCredentials: true });
      if (response.data.success) {
        navigate('/'); // Redirect to home page after successful login
      }
    } catch (error) {
      console.error('Dev login failed:', error);
      alert('Dev login failed');
    }
  };

  return (
    <div className="login-container">
      <img src="/1729034292_5598c136d93e48998477f9440dfaaddf.png" alt="Transmit Logo" className="login-logo" /> {/* Add your logo here */}
      <div className="login-header">
        {/*<h1>Transmit</h1>*/}
        <h1>Welcome to Transmit.chat</h1>
        <p></p>
        <button onClick={handleLogin} type="submit">Login with Google</button>
      </div>
      

      {process.env.NODE_ENV === 'development' && (
        <form onSubmit={handleDevLogin} className="login-form">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter username for dev login"
            required
          />
          <button type="submit">Dev Login</button>
        </form>
      )}
      <div className="terms-and-conditions">
        <p>
          By logging in, you accept our <strong>Terms & Conditions</strong> and <strong>Privacy Policy</strong>. 
          Transmit is committed to protecting your privacy. We collect and store basic data from your Google profile, including your name and email address, but we do not store your passwords. 
          All text chats and messages exchanged through Transmit will be stored indefinitely for the purpose of improving our services and ensuring compliance with our community guidelines.
        </p>
        <p>
          You acknowledge that:
        </p>
        <ul>
          <li>Your use of Transmit is at your own risk. We provide the service "as is" and "as available" without any warranties of any kind.</li>
          <li>We reserve the right to modify or discontinue the service at any time without prior notice.</li>
          <li>You are responsible for maintaining the confidentiality of your account and password, and for restricting access to your computer and account.</li>
          <li>You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</li>
          <li>We may use your data to communicate with you about your account, our services, and promotional offers.</li>
          <li>We may also collect usage data to improve our services and user experience.</li>
        </ul>
        {/*
        <p>
          For more information on how we handle your data, please refer to our <strong>Privacy Policy</strong>.
        </p>
        */}
      </div>
    </div>
  );
};

export default Login;