// utils/serverAPI.js
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

let userSettings = {
  noiseGateThreshold: 0.07943282347242814, // Default value
};

// Fetch current user and servers
export const fetchUserAndServers = async (setUser, setServers, navigate) => {
  try {
    const userRes = await axios.get(`${API_URL}/auth/current_user`, {
      withCredentials: true,
    });
    setUser(userRes.data);

    // Update userSettings with fetched data
    if (userRes.data.settings) {
      userSettings = { ...userSettings, ...userRes.data.settings };
    }

    const serversRes = await axios.get(`${API_URL}/api/servers`, {
      withCredentials: true,
    });
    setServers(serversRes.data);

    return userRes.data; // Return the user data
  } catch (err) {
    console.error('Error fetching user or servers:', err);
    navigate('/login');
    return null;
  }
};

export const getUserSettings = () => userSettings;

export const updateUserSettings = async (newSettings) => {
  try {
    const response = await axios.post(`${API_URL}/api/users/settings`, newSettings, { withCredentials: true });
    userSettings = { ...userSettings, ...newSettings };
    return response.data;
  } catch (error) {
    console.error('Error updating user settings:', error);
    throw error;
  }
};

let noiseGateUpdateCallback = null;

export const setNoiseGateUpdateCallback = (callback) => {
  noiseGateUpdateCallback = callback;
};

export const updateNoiseGateThreshold = (newThreshold) => {
  userSettings.noiseGateThreshold = newThreshold;
  if (noiseGateUpdateCallback) {
    noiseGateUpdateCallback(newThreshold);
  }
};

// Create a new server
export const createServer = async (name, isInviteOnly) => {
  try {
    const response = await axios.post(`${API_URL}/api/servers`, { name, isInviteOnly }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to create server');
  }
};

// Join a server using join code
export const joinServer = async (serverJoinCode, setServers, servers) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/servers/join`,
      { joinCode: serverJoinCode },
      { withCredentials: true }
    );
    setServers([...servers, res.data]);
  } catch (err) {
    console.error('Error joining server:', err);
  }
};

export const generateJoinCode = async (serverId, expiresIn, extend = false) => {
  try {
    const response = await axios.post(`${API_URL}/api/servers/${serverId}/join-code`, { expiresIn, extend }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate or extend join code');
  }
};

// Fetch channels for a specific server
export const fetchChannels = async (selectedServer, setChannels) => {
  if (!selectedServer) return;
  try {
    const res = await axios.get(`${API_URL}/api/servers/${selectedServer}/channels`, {
      withCredentials: true,
    });
    setChannels(res.data);
  } catch (error) {
    console.error('Error fetching channels:', error);
  }
};

// Create a new channel within a selected server
export const createChannel = async (selectedServer, channelName, setChannels, channels) => {
  try {
    const res = await axios.post(
      `${API_URL}/api/servers/${selectedServer}/channels`,
      { name: channelName },
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    console.error('Error creating channel:', err);
    throw err;
  }
};

// Rename a server
export const renameServer = async (serverId, newName) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/servers/${serverId}`,
      { name: newName },
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    console.error('Error renaming server:', err);
    throw err;
  }
};

// Delete a server
export const deleteServer = async (serverId) => {
  try {
    const res = await axios.delete(
      `${API_URL}/api/servers/${serverId}`,
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    console.error('Error deleting server:', err);
    throw err;
  }
};

// Rename a channel
export const renameChannel = async (serverId, channelId, newName) => {
  try {
    const res = await axios.put(
      `${API_URL}/api/servers/${serverId}/channels/${channelId}`,
      { name: newName },
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    console.error('Error renaming channel:', err);
    throw err;
  }
};

// Delete a channel
export const deleteChannel = async (serverId, channelId) => {
  try {
    const res = await axios.delete(
      `${API_URL}/api/servers/${serverId}/channels/${channelId}`,
      { withCredentials: true }
    );
    return res.data;
  } catch (err) {
    console.error('Error deleting channel:', err);
    throw err;
  }
};

export const inviteUserToServer = async (serverId, username, email) => {
  try {
    const response = await axios.post(`${API_URL}/api/servers/${serverId}/invite`, { username, email }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to send server invite');
  }
};

export const fetchPendingServerInvites = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/servers/invites/pending`, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch pending server invites');
  }
};

export const acceptServerInvite = async (inviteId) => {
  try {
    const response = await axios.post(`${API_URL}/api/servers/invites/${inviteId}`, { action: 'accept' }, {
      withCredentials: true
    },
    fetchUserAndServers,);
    return response.data;
  } catch (error) {
    throw new Error('Failed to accept server invite');
  }
};

export const rejectServerInvite = async (inviteId) => {
  try {
    const response = await axios.post(`${API_URL}/api/servers/invites/${inviteId}`, { action: 'reject' }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to reject server invite');
  }
};

export const updateUsername = async (newUsername) => {
  try {
    const response = await axios.put(`${API_URL}/api/users/username`, { username: newUsername }, {
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to update username');
    }
  }
};

export const logoutUser = async () => {
  try {
    await axios.get(`${API_URL}/auth/logout`, {
      withCredentials: true
    });
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};
