// /frontend/src/pages/Home.js

import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { WebSocketContext } from '../contexts/WebSocketContext';
import {
  fetchUserAndServers,
  createServer,
  joinServer,
  createChannel,
  renameServer,
  deleteServer,
  renameChannel,
  deleteChannel,
  generateJoinCode,
  inviteUserToServer,
  fetchPendingServerInvites,
  acceptServerInvite,
  rejectServerInvite,
  updateUsername,
  logoutUser,
  updateUserSettings,
  getUserSettings,
  updateNoiseGateThreshold,
} from '../utils/serverAPI';
import useChannel from '../hooks/useChannel';
import useServer from '../hooks/useServer';
import './Home.css';
import { FaCog, FaEllipsisH, FaVolumeUp, FaPlus, FaHome, FaQuestion } from 'react-icons/fa';
import ChannelSettingsPopup from '../components/ChannelSettingsPopup';
import ServerSettingsPopup from '../components/ServerSettingsPopup';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FriendsList from '../components/FriendsList';
//import DirectMessages from '../components/DirectMessages';
import useFriends from '../hooks/useFriends';
import useChat from '../hooks/useChat';
import ChatWindow from '../components/ChatWindow';
import axios from 'axios';

const Tooltip = ({ text, visible, x, y }) => {
  if (!visible) return null;
  return (
    <div className="tooltip" style={{ left: `${x}px`, top: `${y}px` }}>
      {text}
    </div>
  );
};

const createScrollHandler = (listRef, topFadeRef, bottomFadeRef) => {
  return () => {
    if (listRef.current && topFadeRef.current && bottomFadeRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      const showTopFade = scrollTop > 0;
      const showBottomFade = scrollTop + clientHeight < scrollHeight;
      
      console.log('Scroll position:', scrollTop, 'Client height:', clientHeight, 'Scroll height:', scrollHeight);
    console.log('Show top fade:', showTopFade, 'Show bottom fade:', showBottomFade);

      // Show/hide top fade
      topFadeRef.current.classList.toggle('visible', showTopFade);
      
      // Show/hide bottom fade
      bottomFadeRef.current.classList.toggle('visible', showBottomFade);
    }
  };
};

const Home = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [user, setUser] = useState(null);
  const [servers, setServers] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [serverName, setServerName] = useState('');
  const [serverJoinCode, setServerJoinCode] = useState('');
  const navigate = useNavigate();
  const socket = useContext(WebSocketContext);
  const [selectedServer, setSelectedServer] = useState(null);
  const [selectedServerId, setSelectedServerId] = useState(null);
  const localAudioRef = useRef(null);
  const [localVolume, setLocalVolume] = useState(1);
  const [selectedUserForPopup, setSelectedUserForPopup] = useState(null);
  const [showServerPopup, setShowServerPopup] = useState(false);
  const [showServerSettings, setShowServerSettings] = useState(false);
  const [showChannelSettings, setShowChannelSettings] = useState(null);
  const [isInviteOnly, setIsInviteOnly] = useState(true);
  const [currentView, setCurrentView] = useState('servers');
  const [selectedFriend, setSelectedFriend] = useState(null);
  const { friends, pendingRequests, addFriend, acceptFriendRequest, rejectFriendRequest, updatePendingRequests } = useFriends();
  const [pendingServerInvites, setPendingServerInvites] = useState([]);
  const [selectedInvite, setSelectedInvite] = useState(null);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [unreadDirectMessages, setUnreadDirectMessages] = useState(0);
  const totalUnreadMessages = Object.values(unreadDirectMessages).reduce((a, b) => a + b, 0);
  const { messages, sendMessage, unreadCounts } = useChat(socket, selectedServerId, selectedFriend?._id, user, setUnreadDirectMessages);
  const { serverChannels } = useServer(socket, setPendingServerInvites, updatePendingRequests, setUnreadDirectMessages);
  const currentThreshold = getUserSettings().noiseGateThreshold;

  // Audio level indicator
  const [audioLevelDB, setAudioLevelDB] = useState(-60);
  const [isAboveThreshold, setIsAboveThreshold] = useState(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const audioLevelBarRef = useRef(null);
  const lastAudioLevelRef = useRef(-60);
  const attackTime = 0.005; // 5ms attack time
  const releaseTime = 0.5; // 150ms release time
  const thresholdToDB = (threshold) => 20 * Math.log10(threshold);
  const dbToThreshold = (db) => Math.pow(10, db / 20);
  const currentThresholdDB = thresholdToDB(currentThreshold);
  const [isMicrophoneTesting, setIsMicrophoneTesting] = useState(false);
  /// Audio level indicator

  const {
    localStream,
    adjustVolume,
    connectToChannel,
    disconnectFromChannel,
    consumerStreams,
    adjustStreamVolume,
    registerSetGlobalGain,
    resumeAudioContext,
    isDeafened,
    toggleDeafen,
    setIsDeafened,
    isMuted,
    toggleMute,
    setIsMuted,
    isConnected,
    updateNoiseGateThreshold: updateChannelNoiseGate
  } = useChannel(socket);

  // Function to handle noise gate threshold change
  //const handleNoiseGateChange = useCallback((e) => {
  //  const newThreshold = parseFloat(e.target.value);
  //  updateNoiseGateThreshold(newThreshold);
  //}, []);

  const handleNoiseGateChange = (event) => {
    const newThresholdDB = parseFloat(event.target.value);
    const newThreshold = dbToThreshold(newThresholdDB);
    updateNoiseGateThreshold(newThreshold);
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get(`${API_URL}/auth/check-session`, { withCredentials: true });
        if (response.data.isAuthenticated) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error('Session check failed:', error);
      }
    };
  
    checkSession();
  }, [API_URL]);

  const saveUserSettings = useCallback(async () => {
    try {
      await updateUserSettings(getUserSettings());
      toast.success('Settings saved successfully');
    } catch (error) {
      console.error('Error saving user settings:', error);
      toast.error('Failed to save settings');
    }
  }, []);

  const handleLogout = async () => {
    try {
      await logoutUser();
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Logout failed. Please try again.');
    }
  };

  const handleSelectFriend = (friend) => {
    setSelectedFriend(friend);
    console.log('Selected friend:', friend);
    setUnreadDirectMessages(prev => ({...prev, [friend._id]: 0}));

    // Additional actions you might want to perform when a friend is selected:
  // For example, you might want to change the current view or load direct messages
  //setCurrentView('directMessage');
  
  // If you have a function to load direct messages, you could call it here
  // loadDirectMessages(friend.id);
  
  // You might also want to clear any previously selected server or channel
  //setSelectedServer(null);
  //setSelectedServerId(null);
  //setSelectedChannel(null);
  };

  const handleHomeClick = () => {
    setCurrentView('home');
    setSelectedServer(null);
    setSelectedServerId(null);
    //setSelectedChannel(null);
  };

  // Add this function to handle user interaction
  const handleUserInteraction = () => {
    resumeAudioContext();
    // Any other logic you want to run on user interaction
  };

  const [globalVolume, setGlobalVolume] = useState(1.0);

  useEffect(() => {
    // Register the setGlobalGain function from useChannel
    registerSetGlobalGain(setGlobalGain);
  }, [registerSetGlobalGain]);

  // Define setGlobalGain to update state if necessary
  const setGlobalGain = (fn) => {
    // This function is provided by useChannel to set the gain
    // Optionally link state if you want UI to reflect changes
  };

  // Function to get initials from server name
  const getServerInitials = (name) => {
    const words = name.split(' ');
    let initials;
  
    if (words.length === 1) {
      // For single words, use the first and last letter
      initials = name.charAt(0).toUpperCase() + name.charAt(name.length - 1).toUpperCase();
    } else {
      // For multiple words, use the first letter of each word
      initials = words.map(word => word.charAt(0).toUpperCase()).join('');
    }
  
    // If it's still only one character (i.e., server name is a single character), duplicate it
    if (initials.length === 1) {
      initials += initials;
    }
    
    // Limit to 3 characters
    initials = initials.substring(0, 3);
    
    return initials;
  };

  useEffect(() => {
    const totalNotifications = pendingRequests.length + totalUnreadMessages;
    // You might want to update some state here if you need this value elsewhere
    // setTotalHomeNotifications(totalNotifications);
  }, [pendingRequests, totalUnreadMessages]);

  useEffect(() => {
    if (localAudioRef.current && localStream) {
      localAudioRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserAndServers(setUser, setServers, navigate);
        if (userData) {
          const invites = await fetchPendingServerInvites();
          setPendingServerInvites(invites);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchData();
  }, [navigate]);

  const handleJoinChannel = async (channel) => {
    try {
      if (selectedChannel && selectedChannel._id === channel._id) {
        console.log('Already connected to this channel.');
        return;
      }
  
      if (selectedChannel) {
        await disconnectFromChannel();
        toast.success(`Disconnected from current channel.`, { autoClose: 1000 });
        setSelectedChannel(null);
      }
  
      await connectToChannel(channel._id);
      setSelectedChannel(channel);
      toast.success(`Connected to ${channel.name}`, { autoClose: 1000 });
  
      // The serverChannels will be updated automatically by the socket event
    } catch (error) {
      console.error('Failed to join channel:', error);
      toast.error(`Failed to join channel: ${error.message}`);
    }
  };

  const handleDisconnect = () => {
    disconnectFromChannel(true);
    setSelectedChannel(null);
    setIsMuted(false);
    setIsDeafened(false);
    //toast.success(`Disconnected from channel.`, { autoClose: 1000 });
  };

    const handleUserClick = (user) => {
    setSelectedUserForPopup(user);
    const userStream = consumerStreams.find(cs => cs.userId === user.id);
  setLocalVolume(userStream?.volume || 1);
  };

  const handleGlobalVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    console.log(`New volume input: ${newVolume}`);
    if (isFinite(newVolume) && newVolume >= 0) {
      setGlobalVolume(newVolume);
      adjustVolume(newVolume); // Adjust via AudioContext
    } else {
      console.warn(`Invalid volume value: ${newVolume}`);
    }
  };

  const handleCreateChannel = async (channelName) => {
    try {
      await createChannel(selectedServerId, channelName);
      // The serverChannels will be updated automatically by the socket event
      toast.success('Channel created successfully');
    } catch (error) {
      console.error('Failed to create channel:', error);
      toast.error(`Failed to create channel: ${error.message}`);
    }
  };

  // Update the createServer function
  const handleCreateServer = async () => {
    try {
      const newServer = await createServer(serverName, isInviteOnly);
      setServers([...servers, newServer]);
      setServerName('');
      setShowServerPopup(false);
      toast.success(`Server created successfully.`, { autoClose: 1000 });
    } catch (error) {
      console.error('Failed to create server:', error);
      toast.error(`Failed to create server: ${error}`);
    }
  };

   // Add a function to generate/update join code
   const handleGenerateJoinCode = async (serverId, expiryDays, extend = false) => {
    try {
      const expiresIn = expiryDays * 24 * 60 * 60 * 1000; // Convert days to milliseconds
      const { joinCode } = await generateJoinCode(serverId, expiresIn, extend);
      
      // Update the servers state using a functional update
      setServers(prevServers => prevServers.map(server => 
        server._id === serverId 
          ? { ...server, joinCode: { code: joinCode.code, expiresAt: joinCode.expiresAt } }
          : server
      ));
  
      // If you're also maintaining a selectedServer state, update it as well
      setSelectedServer(prevServer => 
        prevServer._id === serverId 
          ? { ...prevServer, joinCode: { code: joinCode.code, expiresAt: joinCode.expiresAt } }
          : prevServer
      );
  
      toast.success(`Join code ${extend ? 'extended' : 'generated'} successfully.`, { autoClose: 1000 });
    } catch (error) {
      console.error('Failed to generate or extend join code:', error);
      toast.error(`Failed to ${extend ? 'extend' : 'generate'} join code: ${error.message}`);
    }
  };

  const handleRenameServer = async (newName) => {
    try {
      const updatedServer = await renameServer(selectedServer._id, newName);
      setServers(servers.map(server => 
        server._id === updatedServer._id ? updatedServer : server
      ));
      setSelectedServer(updatedServer);
    } catch (error) {
      console.error('Failed to rename server:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleDeleteServer = async () => {
    try {
      await deleteServer(selectedServer._id);
      setServers(servers.filter(server => server._id !== selectedServer._id));
      setSelectedServer(null);
      setSelectedServerId(null);
    } catch (error) {
      console.error('Failed to delete server:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleRenameChannel = async (channelId, newName) => {
    try {
      await renameChannel(selectedServerId, channelId, newName);
    } catch (error) {
      console.error('Failed to rename channel:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleDeleteChannel = async (channelId) => {
    try {
      await deleteChannel(selectedServerId, channelId);
      if (selectedChannel && selectedChannel._id === channelId) {
        setSelectedChannel(null);
      }
    } catch (error) {
      console.error('Failed to delete channel:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleInviteUser = async (serverId, username, email) => {
    try {
      await inviteUserToServer(serverId, username, email);
      toast.success('Invite sent successfully');
    } catch (error) {
      toast.error('Failed to send invite');
    }
  };

  const handleAcceptInvite = async (inviteId) => {
    try {
      await acceptServerInvite(inviteId);
      setPendingServerInvites(prevInvites => prevInvites.filter(invite => invite._id !== inviteId));
      fetchUserAndServers(setUser, setServers, navigate);
      toast.success('Invite accepted');
      setSelectedInvite(null); // Close the popup
    } catch (error) {
      toast.error('Failed to accept invite');
    }
  };

  const handleRejectInvite = async (inviteId) => {
    try {
      await rejectServerInvite(inviteId);
      setPendingServerInvites(prevInvites => prevInvites.filter(invite => invite._id !== inviteId));
      toast.success('Invite rejected');
      setSelectedInvite(null); // Close the popup
    } catch (error) {
      toast.error('Failed to reject invite');
    }
  };

  // Add this function to get user initials
  const getUserInitials = (username) => {
    return username
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  const [tooltip, setTooltip] = useState({ text: '', visible: false, x: 0, y: 0 });
  const [tooltipTimeout, setTooltipTimeout] = useState(null);

  const showTooltip = useCallback((event) => {
    const tooltipText = event.currentTarget.getAttribute('data-tooltip');
    if (tooltipText) {
      const rect = event.currentTarget.getBoundingClientRect();
      const timeout = setTimeout(() => {
      setTooltip({
        text: tooltipText,
        visible: true,
        x: rect.right + 10,
        y: rect.top + rect.height / 2,
      });
    }, 500); // 200ms delay
    setTooltipTimeout(timeout);
    }
  }, []);

  const hideTooltip = useCallback(() => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
    setTooltip(prev => ({ ...prev, visible: false }));
  }, [tooltipTimeout]);

  const channelListRef = useRef(null);
  const topChannelFadeRef = useRef(null);
  const bottomChannelFadeRef = useRef(null);
  const serverListRef = useRef(null);
  const topServerFadeRef = useRef(null);
  const bottomServerFadeRef = useRef(null);

  const handleChannelScroll = useCallback(() => {
    createScrollHandler(channelListRef, topChannelFadeRef, bottomChannelFadeRef)();
  }, []);

  const handleServerScroll = useCallback(() => {
    createScrollHandler(serverListRef, topServerFadeRef, bottomServerFadeRef)();
  }, []);

  useEffect(() => {
    const currentServerListRef = serverListRef.current;
    const currentChannelListRef = channelListRef.current;
  
    if (currentServerListRef) {
      currentServerListRef.addEventListener('scroll', handleServerScroll);
      handleServerScroll();
    }
    if (currentChannelListRef) {
      currentChannelListRef.addEventListener('scroll', handleChannelScroll);
      handleChannelScroll();
    }
  
    return () => {
      if (currentServerListRef) {
        currentServerListRef.removeEventListener('scroll', handleServerScroll);
      }
      if (currentChannelListRef) {
        currentChannelListRef.removeEventListener('scroll', handleChannelScroll);
      }
    };
  }, [handleChannelScroll, handleServerScroll, currentView]);

  const handleUpdateUsername = async () => {
    try {
      const updatedUser = await updateUsername(newUsername);
      setUser(updatedUser);
      setShowProfileSettings(false);
      setNewUsername('');
      toast.success('Username updated successfully');
    } catch (error) {
      toast.error(error.message);
      console.error('Error updating username:', error);
    }
  };

  const toggleMicrophoneTest = () => {
    setIsMicrophoneTesting(prev => !prev);
  };

  useEffect(() => {
    let stream;
    let cleanupFunction;
  
    const setupAudioAnalyser = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 1024;
        source.connect(analyserRef.current);

        let lastUpdateTime = performance.now();

        const updateAudioLevel = () => {
          const currentTime = performance.now();
          const deltaTime = (currentTime - lastUpdateTime) / 1000; // Convert to seconds
          lastUpdateTime = currentTime;

          const dataArray = new Float32Array(analyserRef.current.fftSize);
          analyserRef.current.getFloatTimeDomainData(dataArray);
          
          // Calculate RMS (Root Mean Square)
          const rms = Math.sqrt(dataArray.reduce((sum, val) => sum + val * val, 0) / dataArray.length);
          
          // Convert to dB, with a minimum of -60 dB
          const instantDbFS = Math.max(20 * Math.log10(rms), -60);

          // Apply attack/release behavior
          let newDbFS;
          if (instantDbFS > lastAudioLevelRef.current) {
            // Attack
            const attackRate = (instantDbFS - lastAudioLevelRef.current) / attackTime;
            newDbFS = lastAudioLevelRef.current + attackRate * deltaTime;
          } else {
            // Release
            const releaseRate = (lastAudioLevelRef.current - instantDbFS) / releaseTime;
            newDbFS = lastAudioLevelRef.current - releaseRate * deltaTime;
          }
          newDbFS = Math.max(Math.min(newDbFS, 0), -60); // Clamp between -60 and 0 dB

          setAudioLevelDB(newDbFS);
          lastAudioLevelRef.current = newDbFS;
          setIsAboveThreshold(newDbFS > currentThresholdDB);

          // Update the audio level bar directly
          if (audioLevelBarRef.current) {
            audioLevelBarRef.current.style.width = `${((newDbFS + 60) / 60) * 100}%`;
            audioLevelBarRef.current.style.backgroundColor = newDbFS > currentThresholdDB ? 'green' : 'red';
          }

          animationFrameRef.current = requestAnimationFrame(updateAudioLevel);
        };

        cleanupFunction = () => {
          if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
          }
          if (audioContextRef.current) {
            audioContextRef.current.close();
          }
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
        };

        updateAudioLevel();
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    if (isMicrophoneTesting) {
      setupAudioAnalyser();
    }

    return () => {
      if (cleanupFunction) {
        cleanupFunction();
      }
    };
  }, [isMicrophoneTesting, currentThresholdDB]);


  if (!socket) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container" onClick={handleUserInteraction}>
      <div className="left-panel">
        <div className="sidebars">
          <div className="server-sidebar-container">
            <div className="server-sidebar-cont">
            <div className="home-icon-container">
            {/* Home button */}
            <div 
              className={`server-icon home-icon ${currentView === 'home' ? 'active' : ''}`}
              onClick={handleHomeClick}
              data-tooltip="Home"
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              <FaHome />
              {(pendingRequests.length > 0 || totalUnreadMessages > 0) && (
                    <div className="notification-badge">
                      {pendingRequests.length + totalUnreadMessages}
                    </div>
                  )}
            </div>
            {/* Separator */}
            <div className="home-icon-separator"></div>
            </div>
            <div className="server-list-scroll-container">
            <div 
              ref={topServerFadeRef}
              className="server-sidebar-fade server-sidebar-fade-top"
            ></div>
            <div 
              ref={serverListRef}
              className="server-sidebar">
                
               {/* Server List */}
              <div className="server-list">

              {pendingServerInvites.length > 0 && (
                  <div className="pending-invites">
                    {pendingServerInvites.map(invite => (
                      <div 
                        key={invite._id} 
                        className="pending-invite-item server-icon"
                        onClick={() => setSelectedInvite(invite)}
                      >
                        {getServerInitials(invite.server.name)}
                        <div className="server-notification-badge">
                        <FaQuestion />
                        </div>
                      </div>
                    ))}
                  </div>
                )}



              {servers.map((server) => (
                  <div
                    key={server._id}
                    onClick={() => {
                      setCurrentView('servers');
                      setSelectedServerId(server._id);
                      setSelectedServer(server);
                    }}
                    className={`server-icon ${selectedServerId === server._id ? 'active' : ''}`}
                    data-tooltip={server.name}
                    onMouseEnter={showTooltip}
                    onMouseLeave={hideTooltip}
                  >
                    {getServerInitials(server.name)}
                    {unreadCounts[server._id] > 0 && (
                      <div className="server-notification-badge">
                        {unreadCounts[server._id]}
                      </div>
                    )}
                  </div>
                ))}
              
              
              <div
                  className="server-icon add-server"
                  onClick={() => setShowServerPopup(true)}
                  data-tooltip="Add or Join Server"
                  onMouseEnter={showTooltip}
                  onMouseLeave={hideTooltip}
                >
                  <FaPlus />
                </div>
                </div>
                
            </div>
            <div 
              ref={bottomServerFadeRef}
              className="server-sidebar-fade server-sidebar-fade-bottom"
            ></div>
            </div>
            </div>
            
          </div>

          {/* Channel list / Friends list */}
          <div className="channel-list-container">
          {currentView === 'servers' ? (
            <div className="channel-list">
              {/* Server Header */}
              <div className="server-header">
              <div className="server-header-name-container">
                <h2>{selectedServer ? selectedServer.name : 'Select a Server'}</h2>
                </div>
                <div className="server-settings-icon-container">
                {selectedServer && (
                  <FaCog 
                    className="server-settings-icon" 
                    onClick={() => setShowServerSettings(true)}
                  />
                )}
                </div>
              </div>
              <div className="channel-list-scroll-container">
                <div 
                ref={topChannelFadeRef}
                className="channel-list-scroll-fade channel-list-scroll-fade-top"
                ></div>
                <div 
                ref={channelListRef}
                className="channel-list-content"
                >
                {selectedServerId && serverChannels[selectedServerId] ? (
                serverChannels[selectedServerId].map((channel) => (
                  <div 
                    key={channel._id} 
                    className={`channel-item ${selectedChannel && selectedChannel._id === channel._id ? 'active' : ''}`}
                    onClick={() => handleJoinChannel(channel)}
                  >
                    {/* Channel Details */}
                    <div className="channel-header">
                      <FaVolumeUp className="channel-icon" />
                      <span className="channel-name">{channel.name}</span>
                      <FaEllipsisH 
                        className="channel-settings-icon" 
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowChannelSettings(channel._id);
                        }}
                      />
                    </div>
                    {/* Users list if needed */}
                    {channel.users && channel.users.length > 0 && (
                  <ul>
                    {channel.users.map((user) => (
                      <li
                        key={user.id}
                        className={`user-item ${selectedChannel && selectedChannel._id === channel._id ? 'clickable' : ''}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (selectedChannel && selectedChannel._id === channel._id) {
                            handleUserClick(user);
                            resumeAudioContext();
                          } else {
                            handleJoinChannel(channel);
                          }
                        }}
                      >
                        <div className="user-avatar">
                          {getUserInitials(user.username)}
                        </div>
                        <span className={`user-name ${user.isDeafened ? 'deafened' : ''} ${user.isMuted ? 'muted' : ''}`}>
                          {user.username} {user.isDeafened ? '(Deafened)' : ''} {user.isMuted ? '(Muted)' : ''}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
                  </div>
                ))
              ) : (
                <div className="no-channels-message">Select a server to start</div>
              )}
              </div>
              <div 
                ref={bottomChannelFadeRef}
                className="channel-list-scroll-fade channel-list-scroll-fade-bottom"
              ></div>
            </div>
            </div>
            ) : currentView === 'home' ? (
              // New friends list
              <FriendsList 
                friends={friends || []}
                pendingRequests={pendingRequests}
                onSelectFriend={handleSelectFriend}
                onAddFriend={addFriend}
                onAcceptRequest={acceptFriendRequest}
                onRejectRequest={rejectFriendRequest}
                unreadDirectMessages={unreadDirectMessages}
              />
            ) : null}
          </div>
        </div>

        {/* Voice Controls */}
        {selectedChannel && (
          <div className="voice-controls">
            <h3>Connected to {selectedChannel.name}</h3>
            {!isConnected && (
              <div className="disconnected-message">
              Disconnected from server. Attempting to reconnect...
            </div>
            )}
            <div className="controls">
              <button onClick={toggleMute}>
                {isMuted ? 'Unmute' : 'Mute'}
              </button>
              <button onClick={toggleDeafen}>
                {isDeafened ? 'Undeafen' : 'Deafen'}
              </button>
              <button onClick={handleDisconnect}>Disconnect</button>
            </div>
            <div className="volume-control-container">
            <div className="volume-control">
              <input
                type="range"
                min="0"
                max="2"
                step="0.01"
                value={globalVolume}
                onChange={handleGlobalVolumeChange}
                className="custom-slider"
                disabled={!isConnected}
              />
              <div
                className="custom-thumb"
                style={{ left: `calc(${(globalVolume / 2) * 100}%)` }}
              >
                {Math.round(globalVolume * 100)}%
              </div>
            </div>
          </div>
          </div>
        )}


         {/* User Profile Section */}
         <div className="user-profile">
          <div className="user-avatar">
            {getUserInitials(user?.username || '')}
          </div>
          <span className="user-name">{user?.username}</span>
          <FaCog 
            className="user-settings-icon" 
            onClick={() => setShowProfileSettings(true)}
          />
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {currentView === 'servers' ? (
          selectedServer ? (
            <ChatWindow 
              serverId={selectedServerId}
              userId={selectedFriend?._id}
              messages={messages}
              sendMessage={sendMessage}
              unreadCounts={unreadCounts}
              currentUser={user}
            />
          ) : (
            <div className="no-server-selected">
              <h2>Select a server to start</h2>
            </div>
          )
        ) : currentView === 'home' ? (
          selectedFriend ? (
            <ChatWindow 
              serverId={selectedServerId}
              userId={selectedFriend?._id}
              messages={messages}
              sendMessage={sendMessage}
              unreadCounts={unreadCounts}
              currentUser={user}
            />
          ) : (
            <div className="no-friend-selected">
              <h2>Select a friend to start chatting</h2>
            </div>
          )
        ): null}
      </div>

      {/* Local Audio (Monitoring Only) */}
      <div className="hidden">
              <h3>Local Audio (Monitoring Only)</h3>
              <audio ref={localAudioRef} autoPlay muted controls />
            </div>

      {/* Hidden Audio Elements for All Consumers */}
      <div className="hidden-audio-streams">
  {consumerStreams.map((cs) => (
    <audio
      key={cs.id}
      id={`audio-${cs.id}`}
      autoPlay
      playsInline
      controls={false}
      muted
      style={{ display: 'none' }}
    />
  ))}
</div>

      {/* Remote Stream Popup */}
{selectedUserForPopup && (
  <div 
    className="popup-overlay" 
    onClick={() => setSelectedUserForPopup(null)}
    >
    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
      <h3>{selectedUserForPopup.username}'s Stream</h3>
      <label>
        Volume:
        <input
          type="range"
          min="0"
          max="2"
          step="0.01"
          value={localVolume}
          onChange={(e) =>
          {const newVolume = parseFloat(e.target.value);
          adjustStreamVolume(selectedUserForPopup.id, newVolume);
          setLocalVolume(newVolume);
          }}
        />
      </label>
      <button onClick={() => setSelectedUserForPopup(null)}>Close</button>
    </div>
  </div>
)}

      {/* Popups */}
      {showServerPopup && (
        <div className="popup-overlay" onClick={() => setShowServerPopup(false)}>
          <div className="popup-content server-popup" onClick={(e) => e.stopPropagation()}>
            {/* Create Server Form */}
            <div className="form-group">
              <h4>Create Server</h4>
              <input
                type="text"
                value={serverName}
                onChange={(e) => setServerName(e.target.value)}
                placeholder="Server Name"
              />
              <label>
              Invite Only:
              <input
                type="checkbox"
                checked={isInviteOnly}
                onChange={(e) => setIsInviteOnly(e.target.checked)}
              />
            </label>
              <button onClick={handleCreateServer}>Create Server</button>
            </div>

            {/* Join Server Form */}
            <div className="form-group">
              <h4>Join Server</h4>
              <input
                type="text"
                value={serverJoinCode}
                onChange={(e) => setServerJoinCode(e.target.value)}
                placeholder="Join Code"
              />
              <button onClick={() => {
                joinServer(serverJoinCode, setServers, servers);
                setShowServerPopup(false);
              }}>
                Join Server
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Server Settings Popup */}
      {showServerSettings && (
              <ServerSettingsPopup 
                server={selectedServer}
                onClose={() => setShowServerSettings(false)}
                onRename={handleRenameServer}
                onDelete={handleDeleteServer}
                onCreateChannel={handleCreateChannel}
                onGenerateJoinCode={handleGenerateJoinCode}
                onInviteUser={handleInviteUser}
              />
            )}

        {/* Channel Settings Popup */}
        {showChannelSettings && selectedServerId && (
          <ChannelSettingsPopup 
            channel={serverChannels[selectedServerId]?.find(c => c._id === showChannelSettings)}
            onClose={() => setShowChannelSettings(null)}
            onRename={handleRenameChannel}
            onDelete={handleDeleteChannel}
          />
        )}


        {/* Server invite popup */}
      {selectedInvite && (
        <div className="popup-overlay" onClick={() => setSelectedInvite(null)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h3>Server Invite</h3>
            <p>You've been invited to join {selectedInvite.server.name}</p>
            <p>Invited by: {selectedInvite.sender.username}</p>
            <button onClick={() => handleAcceptInvite(selectedInvite._id)}>Accept</button>
            <button onClick={() => handleRejectInvite(selectedInvite._id)}>Reject</button>
          </div>
        </div>
      )}

      {/* Profile Settings Popup */}
      {showProfileSettings && (
        <div className="popup-overlay" onClick={() => setShowProfileSettings(false)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h3>Profile Settings</h3>
            <div className="form-group">
              <label htmlFor="username">New Username:</label>
              <input
                type="text"
                id="username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                placeholder="Enter new username"
              />
            </div>
            <button onClick={handleUpdateUsername}>Update Username</button>
            <div className="setting-group">
        <label htmlFor="noiseGate">Mic Input Sensitivity:</label>
        <input
          type="range"
          id="noiseGate"
          min="-40"
          max="-14"
          step="0.1"
          value={currentThresholdDB}
          onChange={handleNoiseGateChange}
        />
        <span>{currentThresholdDB.toFixed(1)} dB</span>
        <button onClick={toggleMicrophoneTest}>
        {isMicrophoneTesting ? "Stop Test" : "Test Microphone"}
        </button>
        {isMicrophoneTesting && (
        <>
        <div className="audio-level-indicator">
          <div 
            ref={audioLevelBarRef}
            className="audio-level-bar" 
          ></div>
        </div>
        <span>{audioLevelDB.toFixed(1)} dB {isAboveThreshold ? '(Above Threshold)' : '(Below Threshold)'}</span>
        </>
          )}
          </div>
            <button onClick={saveUserSettings}>Save Settings</button>          
            <button onClick={handleLogout} className="logout-button">Logout</button>
            <button onClick={() => [setShowProfileSettings(false), setIsMicrophoneTesting(false)]}>Cancel</button>
          </div>
        </div>
      )}

      {/* Additional Popups (e.g., ServerSettingsPopup, ChannelSettingsPopup) */}
      {/* ... */}

      <Tooltip {...tooltip} />
      <ToastContainer autoClose={3000} /> {/* Closes after 3 seconds */}
    </div>
  );
};

export default Home;