import React, { useState } from 'react';

const ChannelSettingsPopup = ({ channel, onClose, onRename, onDelete }) => {
  const [newChannelName, setNewChannelName] = useState(channel.name);

  const handleRename = () => {
    onRename(channel._id, newChannelName);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this channel? This action cannot be undone.')) {
      onDelete(channel._id);
      onClose();
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Channel Settings</h3>
        <input
          type="text"
          value={newChannelName}
          onChange={(e) => setNewChannelName(e.target.value)}
        />
        <button onClick={handleRename}>Rename Channel</button>
        <button onClick={handleDelete}>Delete Channel</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ChannelSettingsPopup;