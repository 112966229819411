import { useState, useEffect, useCallback, useRef } from 'react';

const useChat = (socket, serverId, userId, currentUser, setUnreadDirectMessages) => {
  const [messages, setMessages] = useState({});
  const [unreadCounts, setUnreadCounts] = useState({});
  const fetchedChats = useRef(new Set());

  const currentChatId = serverId || userId;

  useEffect(() => {
    if (socket) {
      const handleNewMessage = (message) => {
        const chatId = message.server || (message.sender._id === currentUser._id ? message.recipient : message.sender._id);
        setMessages(prevMessages => ({
          ...prevMessages,
          [chatId]: [...(prevMessages[chatId] || []), message]
        }));

        if (message.sender._id !== currentUser._id && chatId !== currentChatId) {
          setUnreadCounts(prev => ({
            ...prev,
            [chatId]: (prev[chatId] || 0) + 1
          }));
        }

        if (message.sender._id !== currentUser._id && !serverId && chatId !== currentChatId && !message.server) {
          // This is a new direct message not from the current user
          setUnreadDirectMessages(prev => ({
            ...prev,
            [message.sender._id]: (prev[message.sender._id] || 0) + 1
          }));
        }
      };

      const handleMessagesFetched = (fetchedMessages) => {
        if (fetchedMessages.length > 0) {
          const chatId = fetchedMessages[0].server || 
            (fetchedMessages[0].sender._id === currentUser._id ? fetchedMessages[0].recipient : fetchedMessages[0].sender._id);
          setMessages(prevMessages => ({
            ...prevMessages,
            [chatId]: fetchedMessages
          }));
          fetchedChats.current.add(chatId);
        }
      };

      socket.on('newMessage', handleNewMessage);
      socket.on('newDirectMessage', handleNewMessage);
      socket.on('messagesFetched', handleMessagesFetched);

      return () => {
        socket.off('newMessage', handleNewMessage);
        socket.off('newDirectMessage', handleNewMessage);
        socket.off('messagesFetched', handleMessagesFetched);
      };
    }
  }, [socket, currentUser, serverId, setUnreadDirectMessages, currentChatId]);

  useEffect(() => {
    if (socket && currentChatId && !fetchedChats.current.has(currentChatId)) {
      socket.emit('fetchMessages', { serverId, recipientId: userId });
    }
    setUnreadCounts(prev => ({ ...prev, [currentChatId]: 0 }));
  }, [socket, serverId, userId, currentChatId]);

  const sendMessage = useCallback((content) => {
    if (content.trim() && socket) {
      if (serverId) {
        socket.emit('sendMessage', { serverId, content });
      } else if (userId) {
        socket.emit('sendMessage', { recipientId: userId, content });
      }
    }
  }, [socket, serverId, userId]);

  return {
    messages: messages[currentChatId] || [],
    sendMessage,
    unreadCounts
  };
};

export default useChat;