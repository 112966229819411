import React, { useState } from 'react';
import { FaUserPlus, FaCheck, FaTimes } from 'react-icons/fa';

const FriendsList = ({ friends, pendingRequests, onSelectFriend, onAddFriend, onAcceptRequest, onRejectRequest, unreadDirectMessages }) => {
  const [showAddFriendPopup, setShowAddFriendPopup] = useState(false);
  const [usernameInput, setUsernameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [processingRequests, setProcessingRequests] = useState({});

  const handleAddFriend = async () => {
    if (usernameInput.trim() || emailInput.trim()) {
      try {
        await onAddFriend(usernameInput.trim(), emailInput.trim());
        setUsernameInput('');
        setEmailInput('');
        setShowAddFriendPopup(false);
      } catch (error) {
        // Handle error if needed
        console.error('Failed to add friend:', error);
      }
      }
    }

    // Function to get initials from server name
  const getUserInitials = (name) => {
    const words = name.split(' ');
    let initials;
  
    if (words.length === 1) {
      // For single words, use the first and last letter
      initials = name.charAt(0).toUpperCase() + name.charAt(name.length - 1).toUpperCase();
    } else {
      // For multiple words, use the first letter of each word
      initials = words.map(word => word.charAt(0).toUpperCase()).join('');
    }
  
    // If it's still only one character (i.e., server name is a single character), duplicate it
    if (initials.length === 1) {
      initials += initials;
    }
    
    // Limit to 3 characters
    initials = initials.substring(0, 3);
    
    return initials;
  };

  const handleAcceptRequest = async (requestId) => {
    setProcessingRequests(prev => ({ ...prev, [requestId]: true }));
    try {
      await onAcceptRequest(requestId);
    } catch (error) {
      console.error('Failed to accept request:', error);
      // Optionally show an error message to the user
    } finally {
      setProcessingRequests(prev => ({ ...prev, [requestId]: false }));
    }
  };
  
  const handleRejectRequest = async (requestId) => {
    setProcessingRequests(prev => ({ ...prev, [requestId]: true }));
    try {
      await onRejectRequest(requestId);
    } catch (error) {
      console.error('Failed to reject request:', error);
      // Optionally show an error message to the user
    } finally {
      setProcessingRequests(prev => ({ ...prev, [requestId]: false }));
    }
  };

  return (
    <div className="friends-list">
      <div className="friends-header">
        <h2>Friends</h2>
        <button onClick={() => setShowAddFriendPopup(true)} className="add-friend-button">
          <FaUserPlus /> Add Friend
        </button>
      </div>


      <div className="friends-scroll-container">

      {pendingRequests.length > 0 && (
  <div className="pending-requests">
    <h3>Pending Requests</h3>
    {pendingRequests.map((request) => (
      <div key={`pending-${request._id}`} className="pending-request-item">
        <div className="friend-avatar">{getUserInitials(request.sender.username)}</div>
        <span className="friend-name">{request.sender.username}</span>
        <div className="request-actions">
          <button 
            onClick={() => handleAcceptRequest(request._id)} 
            className="accept-button"
            disabled={processingRequests[request._id]}
          >
            <FaCheck /> Accept
          </button>
          <button 
            onClick={() => handleRejectRequest(request._id)} 
            className="reject-button"
            disabled={processingRequests[request._id]}
          >
            <FaTimes /> Reject
          </button>
        </div>
      </div>
    ))}
  </div>
)}



        {friends.map(friend => (
        <div 
        key={`friend-${friend._id}`} 
        className="friend-item" 
        onClick={() => onSelectFriend(friend)}
          >
          <div className="friend-avatar">{getUserInitials(friend.username)}</div>
          <span className="friend-name">{friend.username}</span>
          {unreadDirectMessages[friend._id] > 0 && (
            <span className="unread-badge">{unreadDirectMessages[friend._id]}</span>
          )}
        </div>
      ))}
      </div>

      {showAddFriendPopup && (
        <div className="popup-overlay" onClick={() => setShowAddFriendPopup(false)}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h3>Add Friend</h3>
            <p>Enter a username OR an email address:</p>
            <input
              type="text"
              value={usernameInput}
              onChange={(e) => setUsernameInput(e.target.value)}
              placeholder="Enter username"
            />
            <input
              type="email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              placeholder="Enter email address"
            />
            <button onClick={handleAddFriend}>Add</button>
            <button onClick={() => setShowAddFriendPopup(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendsList;