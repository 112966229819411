// frontend/src/contexts/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

// Replace this with your actual server URL
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL;

export const WebSocketContext = createContext();

export const useSocket = () => {
  const context = useContext(WebSocketContext);
  console.log('useSocket context:', context);
  if (!context) {
    throw new Error('useSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  // Initialize socket synchronously
  const socket = React.useMemo(() => {
    console.log('WebSocketProvider initialized');
    return io(SOCKET_SERVER_URL, {
      withCredentials: true, // If needed for CORS credentials
    });
  }, []);

  useEffect(() => {
    // Log connection
    socket.on('connect', () => {
      console.log('Socket.IO connected');
    });

    // Handle disconnection
    socket.on('disconnect', () => {
      console.log('Socket.IO disconnected');
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};
