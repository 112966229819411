import React, { useState } from 'react';
import { toast } from 'react-toastify';

const ServerSettingsPopup = ({ server, onClose, onRename, onDelete, onCreateChannel, onGenerateJoinCode, onInviteUser }) => {
  const [newServerName, setNewServerName] = useState(server.name);
  const [newChannelName, setNewChannelName] = useState('');
  const [joinCodeExpiry, setJoinCodeExpiry] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inviteUsername, setInviteUsername] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');

  const handleRename = () => {
    onRename(newServerName);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this server? This action cannot be undone.')) {
      onDelete();
      onClose();
    }
  };

  const handleCreateChannel = () => {
    onCreateChannel(newChannelName);
    setNewChannelName('');
    onClose();
  };

  const handleGenerateJoinCode = (extend = false) => {
    if (!extend && server.joinCode) {
      setShowConfirmation(true);
    } else {
      onGenerateJoinCode(server._id, joinCodeExpiry, extend);
    }
  };

  const confirmNewJoinCode = () => {
    onGenerateJoinCode(server._id, joinCodeExpiry, false);
    setShowConfirmation(false);
  };

  const copyJoinCode = () => {
    navigator.clipboard.writeText(server.joinCode.code)
      .then(() => {
        // You can add a toast notification here if you want
        console.log('Join code copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy join code: ', err);
      });
  };

  const handleInviteUser = () => {
    if (inviteUsername || inviteEmail) {
      onInviteUser(server._id, inviteUsername, inviteEmail);
      setInviteUsername('');
      setInviteEmail('');
    } else {
      toast.error('Please enter a username or email');
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Server Settings</h3>
        <input
          type="text"
          value={newServerName}
          onChange={(e) => setNewServerName(e.target.value)}
        />
        <button onClick={handleRename}>Rename Server</button>
        <button onClick={handleDelete}>Delete Server</button>

        {!server.isInviteOnly && (
          <div>
            <h4>Join Code</h4>

            {server.joinCode && (
              <>
                <div className="join-code-container">
                  <p className="join-code">{server.joinCode.code}</p>
                  <button className="copy-button" onClick={copyJoinCode}>Copy</button>
                </div>
                <p className="expiry-info">Expires at: {new Date(server.joinCode.expiresAt).toLocaleString()}</p>
              </>
            )}

            <input
              type="number"
              className="join-code-input"
              value={joinCodeExpiry}
              onChange={(e) => setJoinCodeExpiry(parseInt(e.target.value))}
              placeholder="Expiry time (days)"
            />
            {server.joinCode ? (
              <>
                <button className="join-code-button" onClick={() => handleGenerateJoinCode(true)}>Extend Join Code</button>
                <button className="join-code-button" onClick={() => handleGenerateJoinCode(false)}>Generate New Join Code</button>
              </>
            ) : (
              <button className="join-code-button" onClick={() => handleGenerateJoinCode(false)}>Generate Join Code</button>
            )}

            {showConfirmation && (
              <div className="confirmation-popup">
                <p>Warning: Generating a new join code will invalidate the existing one. Are you sure you want to proceed?</p>
                <button onClick={confirmNewJoinCode}>Yes, generate new code</button>
                <button onClick={() => setShowConfirmation(false)}>Cancel</button>
              </div>
            )}

            
          </div>
        )}

        <h4>Invite User</h4>
        <input
          type="text"
          value={inviteUsername}
          onChange={(e) => setInviteUsername(e.target.value)}
          placeholder="Username"
        />
        <input
          type="email"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          placeholder="Email"
        />
        <button onClick={handleInviteUser}>Send Invite</button>

        <h4>Create Channel</h4>
        <input
          type="text"
          value={newChannelName}
          onChange={(e) => setNewChannelName(e.target.value)}
          placeholder="New Channel Name"
        />
        <button onClick={handleCreateChannel}>Create Channel</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ServerSettingsPopup;